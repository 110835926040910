import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import { ErrText } from "./styled.components";
import Grid from "@mui/material/Grid";

import { TextField, Button, Backdrop, CircularProgress } from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import ApiService from "../services/api.service";
import { useNavigate } from "react-router-dom";
import useUser from "store/user/useUser";
import useLoader from "hooks/useLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fafafa",
    padding: "5vh",
  },
  btn: {
    borderRadius: "30px",
  },
}));

export default ({ callback = () => {} }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [badCredential, setBadCredential] = useState(false);
  const { loadUser } = useUser();
  const validationSchema = yup.object({
    email: yup.string().required(t("email_required")),
    password: yup.string().required(t("password_required")),
  });
  const { isLoading, data: loginData, error: loginError, execute } = useLoader();

  useEffect(() => {
    if (loginData) {
      window.localStorage.setItem("jwt", loginData.token);
      loadUser();
      callback();
    }
  }, [loginData]);

  useEffect(() => {
    if (loginError?.errCode == "BAD_CREDENTIAL") {
      setBadCredential(true);
    }
  }, [loginError]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const { email, password } = values;
      execute(() => {
        return ApiService.login(email, password);
      });
    },
  });

  const isError = (fieldName) => {
    return formik.touched[fieldName] && Boolean(formik.errors[fieldName]);
  };

  const getErrorText = (fieldName) => {
    if (isError(fieldName)) {
      return formik.errors[fieldName];
    }

    return "";
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <TextField
            id="email"
            placeholder={t("email")}
            fullWidth
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={isError("email")}
            helperText={getErrorText("email")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="password"
            type="password"
            placeholder={t("password")}
            fullWidth
            variant="outlined"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={isError("password")}
            helperText={getErrorText("password")}
          />
        </Grid>

        {badCredential && <ErrText>{t("bad_credential")}</ErrText>}

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.btn}
            type="submit"
          >
            {" "}
            {t("login")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
