import { useState } from "react";
import Grid from "@mui/material/Grid";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";

//import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Modal,
  Link,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import Login from "./login.component";
import useUser from "../store/user/useUser";
import useGeneral from "../store/general/useGeneral";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fafafa",
    borderRadius: "10px",
    padding: "0.5rem",
    minWidth: "150px",
  },
  subheader: {
    textAlign: "center",
    color: "grey",
    fontWeight: "bold",
  },
  forum: {
    textAlign: "center",
  },
  loginModal: {
    width: "600px",
    margin: "auto",
    marginTop: "20vh",
  },
}));

export default () => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const [isLoginOpen, setLoginOpen] = useState(false);
  const { isLogin, logout } = useUser();
  const { menuCategories } = useGeneral();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        borderRadius: "10px",
        padding: "0.5rem",
        minWidth: "180px",
        bgcolor: "#fafafa",
      }}
    >
      <List>
        {isLogin ? (
          <>
            <ListItem button key="profile" onClick={() => navigate("/profile")}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText>{t("profile")}</ListItemText>
            </ListItem>
            <ListItem button key="logout" onClick={() => logout()}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText> {t("logout")} </ListItemText>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem button key="login" onClick={() => setLoginOpen(true)}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText> {t("login")} </ListItemText>
            </ListItem>
            <ListItem button key="signup" onClick={() => navigate("/signup")}>
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText>{t("signup")}</ListItemText>
            </ListItem>
          </>
        )}
        <ListItem>
          <ListItemButton onClick={() => navigate("/use_term")}>
            <ListItemText> {t("use_term")}</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {menuCategories.map((cat) => (
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(`/post_list/${cat.code}`)}>
              <ListItemText>{cat.name}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Modal open={isLoginOpen} onClose={() => setLoginOpen(false)}>
        <div className={classes.loginModal}>
          <Login callback={() => setLoginOpen(false)} />
        </div>
      </Modal>
    </Box>
  );
};
