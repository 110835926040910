import { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "components/layout.component";
import { makeStyles } from "@mui/styles";
import { Box, ThemeProvider, createTheme } from "@mui/system";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ApiService from "services/api.service";
import { formatTimeDistance } from "services/utils.service";
import { Divider } from "components/styled.components";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    marginLeft: "2rem",
    fontSize: "1.2rem",
  },
  backArrow: {
    padding: "5px",
    cursor: "pointer",
  },
  icon: {
    width: "0.5rem",
    height: "0.5rem",
  },
  subtitle: {
    marginTop: "1rem",
    fontWeight: "bold",
    fontSize: "medium",
  },
  username: {
    color: theme.palette.primary.main,
  },
}));

export default function () {
  const { userId } = useParams();
  const { t } = useTranslation("user_activities");
  const [posts, setPosts] = useState([]);
  const [user, setUser] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(async () => {
    let resp = await ApiService.getPostsCreatedBy(userId);
    const posts = _.map(resp.data, (post) => {
      return {
        ...post,
        createdAt: new Date(post.createdAt),
      };
    });
    const { data: user } = await ApiService.getUserProfile(userId);
    setUser(user);
    setPosts(posts);
  }, []);

  return (
    <Layout>
      <Box sx={{ padding: "1rem", backgroundColor: "#fafafa", marginLeft: "1rem" }}>
        <div className={classes.header}>
          <div className={classes.backArrow}>
            <ArrowBackIosIcon fontSize="small" onClick={() => navigate(-1)}></ArrowBackIosIcon>
          </div>
          <div className={classes.title}>
            {t("check_profile", {
              username: user?.username,
            })}
          </div>
        </div>
        <div className={classes.subtitle}>{t("created_posts")}</div>
        <Box sx={{ marginTop: "2rem" }}>
          {_.map(posts, (post) => {
            return (
              <Box
                sx={{ cursor: "pointer" }}
                onClick={() => navigate(`/post_details/${post.postId}`)}
              >
                <Box sx={{ display: "flex", direction: "row" }}>
                  <Box sx={{ color: "primary.main" }}>{post.user?.username}</Box>
                  <Box sx={{ fontSize: "12px", color: "#808080", marginLeft: "1rem" }}>
                    {" "}
                    {formatTimeDistance(post.createdAt)}{" "}
                  </Box>
                  <Box sx={{ marginLeft: "1rem" }}>
                    {" "}
                    <ThumbUpIcon
                      fontSize="small"
                      sx={{ height: "15px", width: "15px", color: "#808080" }}
                    />{" "}
                  </Box>
                  <Box sx={{ marginLeft: "10px", fontSize: "12px" }}> {post.likes} </Box>
                </Box>
                <Box sx={{ marginTop: "0.5rem" }}>{post.title}</Box>
                <Divider />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Layout>
  );
}
