import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { SHOW_LOGIN_MODAL, HIDE_LOGIN_MODAL, SET_CATEGORIES } from "./general.actions";
import useUser from "../user/useUser";
import ApiService from "services/api.service";

const generalSelector = (state) => state.general;

const isLoginModalOpenSelector = createSelector(generalSelector, (state) => state.loginModalOpen);

const categoriesSelector = createSelector(generalSelector, (state) => state.categories);

const menuCategoriesSelector = createSelector(categoriesSelector, (categories) => [
  {
    id: 0,
    code: "all",
    name: "全部",
  },
  ...categories,
]);

const useLoginModal = () => {
  const dispatch = useDispatch();
  const { isLogin } = useUser();

  const isLoginModalOpen = useSelector(isLoginModalOpenSelector);

  const categories = useSelector(categoriesSelector);

  const menuCategories = useSelector(menuCategoriesSelector);

  const openLoginModal = () => {
    dispatch({
      type: SHOW_LOGIN_MODAL,
    });
  };

  const closeLoginModal = () => {
    dispatch({
      type: HIDE_LOGIN_MODAL,
    });
  };

  const checkLoginFirst = (f) => {
    if (!isLogin) {
      return openLoginModal();
    }
    f();
  };

  const loadCategories = () => {
    ApiService.getCategories().then((resp) => {
      dispatch({
        type: SET_CATEGORIES,
        categories: resp.data,
      });
    });
  };

  return {
    isLoginModalOpen,
    openLoginModal,
    closeLoginModal,
    checkLoginFirst,
    loadCategories,
    categories,
    menuCategories,
  };
};

export default useLoginModal;
