import { Link, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import ApiService from "../services/api.service";
import Layout from "../components/layout.component";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import {
  IconButton,
  Button,
  Tabs,
  Tab,
  Fab,
  AppBar,
  TextField,
  CircularProgress,
  Backdrop,
  Alert,
  Snackbar,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { VSpacer } from "components/styled.components";
import Pagination from "@mui/material/Pagination";

import PostSummary from "components/post_summary.component";
import useLoginModal from "store/general/useGeneral";
import useUser from "store/user/useUser";
import * as _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem",
    "& > :not(:first-child)": {
      marginTop: "1rem",
    },
  },
  titleRow: {
    display: "flex",
    direction: "row",
  },
  plus: {
    marginLeft: "auto",
    marginRight: "1rem",
    marginTop: "1rem",
  },
  image: {
    borderRadius: "100%",
    height: "80px",
    width: "80px",
  },
  titleName: {
    margin: "0.5rem",
    fontSize: "1.5rem",
  },
  sortBy: {
    magin: "0.5rem",
  },
  addFab: {
    marginTop: "2vh",
    height: "40px",
    width: "40px",
  },
  newPostRow: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  posts: {
    "& > div": {
      marginTop: "1.5rem",
    },
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  dropdown: {
    margin: theme.spacing(1),
    minWidth: 60,
  },
  loader: {
    marginTop: "3rem",
    marginLeft: "3rem",
  },
}));

export default function () {
  const classes = useStyles();
  const { catCode } = useParams();
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const page = searchParams.get("page") || 1;
  const { t } = useTranslation("common");
  const { isLogin } = useUser();
  const { openLoginModal } = useLoginModal();
  const navigate = useNavigate();
  const [img, setImg] = useState();
  const [isLoading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  useEffect(async () => {
    try {
      setLoading(true);
      setLoadingError(false);
      const {
        data: { posts, totalPages },
      } = await ApiService.getPagePosts(catCode, null, 1);
      setPosts(
        _.map(posts, (post) => {
          return {
            ...post,
            updatedAt: new Date(post.updatedAt),
          };
        })
      );
      setTotalPages(totalPages);
      const { data } = await ApiService.getCategoryInfo(catCode);
      setCategory(data);
      setImg(require(`images/${data.imageName}`));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setLoadingError(true);
    }
  }, [catCode]);

  return (
    <Layout>
      {loadingError ? (
        <Alert elevation={6} variant="filled" severity="error">
          {t("system_error")}
        </Alert>
      ) : (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className={classes.root}>
            <div className={classes.titleRow}>
              <div>
                <img src={img} className={classes.image} />
              </div>
              <div className={classes.titleName}>{category?.name}</div>
              <div className={classes.plus}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.newPostBtn}
                  sx={{
                    borderRadius: 2,
                  }}
                  onClick={() => {
                    if (!isLogin) {
                      openLoginModal();
                    } else {
                      navigate("/new_post");
                    }
                  }}
                >
                  {t("new_post")}
                </Button>
              </div>
            </div>

            {location?.state?.isVerified && (
              <Alert variant="filled" severity="success">
                {t("success_verification")}
              </Alert>
            )}

            <div className={classes.posts}>
              {posts.map((post, index) => {
                return (
                  <div>
                    <PostSummary summary={post} />
                  </div>
                );
              })}
            </div>
            <div>
              <Pagination
                page={page}
                count={totalPages}
                color="primary"
                onChange={(e, targetPage) => {
                  navigate(`/post_list/${catCode}?page=${targetPage}`);
                }}
              />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}
