import { useState } from "react";

export default function useLoader() {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const execute = async (apiCall) => {
    try {
      setLoading(true);
      let resp = await apiCall();
      setData(resp.data);
    } catch (err) {
      setError(err?.response?.data);
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, data, error, execute };
}
