import { useRef, forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@mui/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import ApiService from "services/api.service";

const useStyles = makeStyles((theme) => ({
  editor: {
    backgroundColor: "white",
  },
}));

const ReplyEditor = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getHTML: () => {
      return quillRef.current.unprivilegedEditor.getHTML();
    },
  }));

  const classes = useStyles();
  const quillRef = useRef(null);

  const modules = {
    toolbar: {
      container: [
        [
          "bold",
          "italic",
          "underline",
          { size: ["small", false, "large"] },
          { color: [] },
          "emoji",
        ],
      ],
    },
    clipboard: { matchVisual: false },
    "emoji-toolbar": true,
  };

  return (
    <div className={classes.editor}>
      <ReactQuill ref={quillRef} theme="snow" modules={modules} />
    </div>
  );
});

export default ReplyEditor;
