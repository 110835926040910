import { SET_USER, LOGOUT } from "./user.actions";

const initState = {
  email: "",
  username: "",
  isLogin: false,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_USER:
      const { email, username, sex, aboutMyself, roles } = action.profile;
      return {
        email,
        username,
        sex,
        aboutMyself,
        isLogin: true,
        roles,
      };
    case LOGOUT:
      return initState;
    default:
      return state;
  }
};

export default reducer;
