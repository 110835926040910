import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import ApiService from "services/api.service";
import { SET_REPLY_STATUSES, SET_AUTHOR } from "./post.actions";
import * as _ from "lodash";

const postSelector = (state) => state.post;
const postReplyLikesSelector = createSelector(postSelector, (post) => post.replyLikeStatuses);
const postAuthorSelector = createSelector(postSelector, (post) => post.author);

const usePost = () => {
  const dispatch = useDispatch();
  const author = useSelector(postAuthorSelector);
  const replyLikes = useSelector(postReplyLikesSelector);

  const setReplyLikes = (replyLikeStatuses) => {
    dispatch({
      type: SET_REPLY_STATUSES,
      replyLikeStatuses,
    });
  };
  const loadReplyLikes = (postId) => {
    ApiService.getPostReplyLikes(postId).then((resp) => {
      setReplyLikes(resp.data);
    });
  };

  const setAuthor = (author) => {
    dispatch({
      type: SET_AUTHOR,
      author,
    });
  };
  return { loadReplyLikes, replyLikes, setAuthor, author };
};

export default usePost;
