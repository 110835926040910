import { fontWeight, styled } from "@mui/system";
import { TextField } from "@mui/material";

export const VSpacer = styled("div")({
  display: "block",
  height: "1vh",
});

export const ErrText = styled("div")({
  display: "block",
  color: "red",
});

export const UserName = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
}));

export const ReplyTime = styled("div")({
  fontSize: "12px",
  color: "grey",
  padding: "3px",
});

export const AuthorLabel = styled("div")({
  fontSize: "10px",
  color: "red",
  padding: "3px",
});

export const Divider = styled("div")({
  borderTop: "1px solid #bbb",
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
});

export const AnswerField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 14,
    width: "auto",
    height: 20,
    padding: "8px 10px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
}));
