import { Box } from "@mui/system";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ApiService from "../services/api.service";
import { useTranslation } from "react-i18next";

export default function () {
  const navigate = useNavigate();
  const [failed, setFailed] = useState(false);
  const { t } = useTranslation("common");

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(async () => {
    try {
      await ApiService.verify(token);
      navigate("/post_list/all", { state: { isVerified: true } });
    } catch (err) {
      setFailed(true);
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20vh" }}>
      {failed && (
        <Alert variant="filled" severity="error">
          {t("error_verification")}
        </Alert>
      )}
    </Box>
  );
}
