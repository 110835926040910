import { useEffect, useState } from "react";
import SidePanel from "./side_nav.component";
import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar, IconButton, Drawer, Modal, Box, Grid } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Login from "./login.component";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import useLoginModal from "../store/general/useGeneral";
import useUser from "../store/user/useUser";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
  },
  sideNav: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  content: {
    marginLeft: "1rem",
    [theme.breakpoints.up("sm")]: {
      minWidth: "500px",
      maxWidth: "800px",
    },
  },
  loginModal: {
    width: "600px",
    margin: "auto",
    marginTop: "20vh",
  },
  spinner: {
    padding: "5rem",
    textAlign: "center",
  },
}));

export default function ({ children }) {
  const classes = useStyles();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { isLoginModalOpen, openLoginModal, closeLoginModal } = useLoginModal();

  const { isLogin } = useUser();
  const history = useNavigate();

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(isOpen);
  };

  const goToEditor = () => {
    if (isLogin) {
      history.push("/new_post");
    } else {
      openLoginModal();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.sideNav}>
        <SidePanel />
      </div>
      <div className={classes.content}>{children}</div>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: "auto",
          bottom: 0,
          display: {
            xs: "block",
            sm: "none",
          },
        }}
        className={classes.footer}
      >
        <Toolbar>
          <IconButton color="inherit" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <IconButton color="inherit" onClick={() => goToEditor()}>
            <AddIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <SidePanel></SidePanel>
      </Drawer>
      <Modal open={isLoginModalOpen} onClose={() => closeLoginModal()}>
        <div className={classes.loginModal}>
          <Login callback={() => closeLoginModal()} />
        </div>
      </Modal>
    </div>
  );
}
