import { useEffect, useRef, useState } from "react";
import useUser from "store/user/useUser";
import { useFormik } from "formik";
import * as yup from "yup";
import ApiService from "services/api.service";
import { Box } from "@mui/system";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  InputLabel,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import WolfEditor from "components/wolf_editor.component";
import useGeneral from "store/general/useGeneral";
import { ErrText } from "../components/styled.components";
import useLoader from "../hooks/useLoader";

const rowCss = {
  display: "flex",
  direction: "column",
  marginTop: "1rem",
};

export default function () {
  const [users, setUsers] = useState([]);
  const { categories } = useGeneral();
  const editorRef = useRef();
  const { isLoading, data: postData, error, execute } = useLoader();
  const formik = useFormik({
    initialValues: {
      userId: "",
      catId: "",
      title: "",
    },
    validationSchema: yup.object({
      userId: yup.number().required("user required"),
      catId: yup.number().required("category required"),
      title: yup.string().required("title required").max(300),
    }),
    onSubmit: async (values, { resetForm }) => {
      const body = editorRef.current.getHTML();
      let content = `<div>${body}</div>`;
      content = content.replaceAll("<p><br></p>", "");
      execute(() =>
        ApiService.createPostAsAdmin(values.userId, values.catId, values.title, content)
      );
      resetForm();
    },
  });

  const isError = (fieldName) => {
    return formik.touched[fieldName] && Boolean(formik.errors[fieldName]);
  };

  useEffect(() => {
    ApiService.getInternalUsers().then((resp) => {
      setUsers(resp.data);
    });
  }, []);

  const createProps = (fieldName) => {
    return {
      value: formik.values[fieldName],
      onChange: formik.handleChange(fieldName),
      onBlur: formik.handleBlur(fieldName),
      error: isError(fieldName),
    };
  };

  return (
    <Box sx={{ backgroundColor: "#fafafa", marginLeft: "15%", marginTop: "2rem", width: "70%" }}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ padding: "3rem", "& .MuiTextField-root": { m: 1, minWidth: "15%" } }}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={rowCss}>
            <TextField select label="Select a user" {...createProps("userId")}>
              {users.map((user) => (
                <MenuItem value={user.id}>{user.username}</MenuItem>
              ))}
            </TextField>
            <ErrText>{formik.errors["userId"]}</ErrText>
          </Box>
          <Box sx={rowCss}>
            <TextField select label="Select a category" {...createProps("catId")}>
              {categories.map((cat) => (
                <MenuItem value={cat.id}>{cat.name}</MenuItem>
              ))}
            </TextField>
            <ErrText>{formik.errors["catId"]}</ErrText>
          </Box>
          <Box sx={rowCss}>
            <TextField label="Title" fullWidth {...createProps("title")}></TextField>
            <ErrText>{formik.errors["title"]}</ErrText>
          </Box>
          <div>
            <WolfEditor ref={editorRef} />
          </div>
          <Box sx={rowCss}>
            <Button variant="contained" color="primary" type="submit">
              Publish
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
