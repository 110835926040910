import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default ({ user }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        color: "secondary.main",
        fontWeight: "bold",
        cursor: "pointer",
      }}
      onClick={() => navigate(`/user_activity/${user?.userId}`)}
    >
      {user?.username}
    </Box>
  );
};
