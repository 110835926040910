import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const resources = {
  zh: {
    common: {
      latest: "最新",
      hottest: "最火",
      load_more: "更多内容",
      new_post: "发帖",
      cancel: "取消",
      reply: "回复",
      comment: "发表评论",
      login_first: "发言前请先登录或注册",
      system_error: "抱歉,系统出错",
      reply_area: "回复区",
      author: "(楼主)",
      email: "登录邮件",
      email_required: "请输入邮件",
      password: "密码",
      password_required: "请输入密码",
      login: "登录",
      bad_credential: "用户名或密码错误",
      signup: "注册",
      messages: "消息",
      profile: "个人资料",
      use_term: "使用协议",
      success_registration: "成功创建账户，请稍候。",
      success_verification: "你的邮件已被验证，欢迎登录发贴！",
      error_verification: "验证链接无效或过期，请重新注册。",
      more_details: "......更多内容",
      notifications: "通知",
      logout: "退出",
      forum: "论坛",
      chat: "吹水台",
      politics: "政治台",
      science: "科技台",
      housing: "地产台",
      military: "军武台",
      buddha: "佛学台",
      title: "用户注册",
      email: "邮件",
      email_invalid: "邮件格式错误",
      email_required: "请输入邮件",
      username: "用户名",
      username_required: "请输入用户名",
      username_helptext: "发言时显示的名称",
      password: "密码",
      password_required: "请输入密码",
      password_min_length: "密码至少要求6位",
      confirm_password: "重新输入密码",
      confirm_password_required: "请重新输入密码",
      confirm_password_matched: "密码不相符",
      read_accept: "我已阅读并接受",
      accept_required: "接受用户条款才能继续",
      verification_label: "请输入以下简单数学题答案",
      verification_error: " 错误,请再算一遍",
      sign_up: "注册",
      user_agreement: "用户协议",
      email_already_used: "邮件已被注册",
      username_already_used: "用户名已被注册",
      sex: "性别",
      male: "巴打",
      female: "丝打",
      about_yourself: "关于自己",
      save: "保存",
      updated_success: "成功更新个人资料",
      return: "返回",
    },
    new_post: {
      select_category: "选择版块",
      title: "主题",
      publish: "发布",
      category_required: "请选择版块",
      title_required: "请填写主题",
      title_long: "主题应少于40字",
      body_long: "内容不能超过400字",
    },
    user_activities: {
      check_profile: "起底会员 {{username}}",
      created_posts: "TA的发布主题",
    },
  },
  en: {
    common: {
      latest: "Latest",
      hottest: "Hottest",
      load_more: "Load more",
      new_post: "Create new post",
      cancel: "Cancel",
      reply: "Reply",
      comment: "Comment",
      login_first: "Please login or register before making a comment.",
      system_error: "A system error occured. Sorry for inconveniance.",
      reply_area: "Replies",
      author: "(Author)",
      email: "Email",
      email_required: "Email is required",
      password: "Password",
      password_required: "Password is required",
      login: "Login",
      bad_credential: "Invalid username or password.",
      login: "Log in",
      signup: "Sign up",
      messages: "Messages",
      profile: "Your profile",
      use_term: "Use Terms",
      success_verification: "Your email has been verified. Enjoy your journey with Da Grey Wolf",
      error_verification: "The verification link is invalid or expired.",
      notifications: "Notifications",
      logout: "Log out",
      forum: "Forums",
      chat: "Chat",
      politics: "Politics",
      housing: "Housing",
      title: "Register an account",
      email: "Email",
      email_invalid: "Invalid email format.",
      email_required: "Email is required",
      username: "Username",
      username_required: "Username is required",
      username_helptext: "For content creation",
      password: "Password",
      password_required: "Password is required",
      confirm_password: "Re-enter password",
      confirm_password_required: "Please confirm the password.",
      confirm_password_matched: "Passwords don't match",
      read_accept: "You read and agree to ",
      accept_required: "Please accept the terms and conditions to continue",
      sign_up: "Sign Up",
      user_agreement: "our User Agreement",
      email_already_used: "Email already used",
      username_already_used: "Username already used",
      sex: "Sex",
      male: "Male",
      female: "Female",
      about_yourself: "About yourself",
      save: "Save",
      updated_success: "Updated profile successfully",
      return: "Return",
    },
    user_activities: {
      check_profile: "Check activities of {{username}}",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "zh",
  keySeparator: ".",
  react: {
    useSuspense: false, //   <---- this will do the magic
  },
});

export default i18n;
