import { SHOW_LOGIN_MODAL, HIDE_LOGIN_MODAL, SET_CATEGORIES } from "./general.actions";

const initState = {
  loginModalOpen: false,
  targetUrl: "",
  categories: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        loginModalOpen: true,
      };
    case HIDE_LOGIN_MODAL:
      return {
        ...state,
        loginModalOpen: false,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    default:
      return state;
  }
};

export default reducer;
