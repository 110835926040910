import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import WolfEditor from "components/wolf_editor.component";
import { ErrText } from "components/styled.components";
import ApiService from "services/api.service";
import useLoader from "../hooks/useLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fafafa",
    margin: "auto",
    marginTop: "5vh",
    width: "800px",
    padding: "3rem",
  },
  formControl: {
    minWidth: 200,
  },
}));

export default () => {
  const editorRef = useRef();
  const classes = useStyles();
  const [bodyTooLong, setBodyTooLong] = useState(false);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation("new_post");
  const schema = yup.object({
    catId: yup.string().required(t("category_required")),
    title: yup.string().required(t("title_required")).max(30, t("title_long")),
  });
  const navigate = useNavigate();
  const { isLoading, data: postData, error, execute } = useLoader();

  useEffect(async () => {
    let resp = await ApiService.getCategories();
    setCategories(resp.data);
  }, []);

  useEffect(() => {
    if (postData) {
      navigate("/");
    }
  }, [postData]);

  const formik = useFormik({
    initialValues: {
      catId: "",
      title: "",
    },
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      const body = editorRef.current.getHTML();
      let content = `<div>${body}</div>`;
      content = content.replaceAll("<p><br></p>", "");
      if (content.length > 600) {
        setBodyTooLong(true);
        return;
      }
      execute(() => {
        return ApiService.createPost(values.catId, values.title, content);
      });
    },
  });

  const isError = (fieldName) => {
    return formik.touched[fieldName] && Boolean(formik.errors[fieldName]);
  };

  return (
    <div className={classes.root}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} sx={{ minWidth: 120 }}>
              <InputLabel> {t("select_category")} </InputLabel>
              <Select
                id="catId"
                value={formik.values.catId}
                onChange={formik.handleChange("catId")}
                onBlur={formik.handleBlur("catId")}
                error={isError("catId")}
              >
                {categories.map((cat) => (
                  <MenuItem value={cat.id} key={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
              <ErrText>{formik.errors["catId"]}</ErrText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="title"
              variant="outlined"
              placeholder={t("title")}
              fullWidth
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={isError("title")}
            />
            <ErrText> {formik.errors["title"]} </ErrText>
          </Grid>
          <Grid item xs={12}>
            <WolfEditor ref={editorRef} />
          </Grid>
          <Grid item xs={12}>
            {bodyTooLong && <ErrText>{t("body_long")}</ErrText>}
            {error && <ErrText>{JSON.stringify(error)}</ErrText>}
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              {t("publish")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
