import userReducer from "./user/user.reducer";
import generalReducer from "./general/general.reducer";
import postReducer from "./post/post.reducer";
import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

export const store = createStore(
  combineReducers({
    user: userReducer,
    general: generalReducer,
    post: postReducer,
  }),
  composeWithDevTools(applyMiddleware())
);
