import { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Checkbox, TextField, Button, Backdrop, CircularProgress, Box } from "@mui/material";

import ApiService from "services/api.service";
import Layout from "components/layout.component";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { makeStyles } from "@mui/styles";
import { VSpacer } from "components/styled.components";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import WolfEditor from "components/wolf_editor.component";
import PostReply from "components/post_reply.component";
import UserLink from "components/user_link.component";

import useUser from "store/user/useUser";
import usePost from "store/post/usePost";
import { UserName, ReplyTime, Divider } from "components/styled.components";
import { formatTimeDistance } from "services/utils.service";

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: "flex",
    flexDirection: "row",
  },
  category: {
    color: theme.palette.primary.main,
    paddingTop: "5px",
    height: "30px",
    minWidth: "65px",
    fontSize: "0.875rem",
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: "20px",
    borderStyle: "solid",
    borderWidth: "thin",
  },
  authorRow: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "row",
    "& > :nth-child(2)": {
      marginLeft: "0.5rem",
    },
  },
  title: {
    marginLeft: "1rem",
    padding: "5px",
    fontSize: "large",
    fontWeight: "bold",
  },
  editorContainer: {
    marginTop: "1rem",
    marginRight: "2vh",
  },
  btns: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  commentBtn: {
    borderRadius: "20px",
  },
  thumbsRow: {
    marginTop: "1rem",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
  },
  replies: {
    marginTop: "1rem",
    padding: "1rem",
    backgroundColor: "#fafafa",
    borderRadius: "10px",
  },
  iconDiv: {
    padding: "5px",
    cursor: "pointer",
  },
  icon: {
    width: "1rem",
    height: "1rem",
  },
  loginMessage: {
    marginTop: "1rem",
  },
  body: {
    "& img": {
      width: "100%",
      maxWidth: "500px",
      height: "auto",
      maxHeight: "500px",
    },
  },
}));

export default function () {
  const { id } = useParams();
  const classes = useStyles();
  const editorRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [post, setPost] = useState();
  const [replies, setReplies] = useState([]);
  const { isLogin, userProfile } = useUser();
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [createdAt, setCreatedAt] = useState();
  const { loadReplyLikes, setAuthor } = usePost();
  const [isLoading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);

    try {
      const { data: post } = await ApiService.getPostDetails(id);
      const { data: replies } = await ApiService.getPostReplies(id);
      const d = new Date(post.createdAt);
      setLoading(false);
      setCreatedAt(formatTimeDistance(d));
      setPost(post);
      setAuthor(post.user);
      setReplies(replies);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isLogin) {
      ApiService.alreadyLikedPost(id).then((resp) => {
        const { liked } = resp.data;
        setLiked(Boolean(liked));
      });
      ApiService.alreadyDislikedPost(id).then((resp) => {
        const { disliked } = resp.data;
        setDisliked(Boolean(disliked));
      });
      loadReplyLikes(id);
    }
  }, [isLogin]);

  const like = async () => {
    try {
      let resp = await ApiService.likePost(post.postId);
      const { increment } = resp.data;
      if (increment >= 1) {
        setLiked(true);
        setDisliked(false);
        setLikes((likes) => likes + increment);
      }
    } catch (error) {}
  };

  const dislike = async () => {
    try {
      let resp = await ApiService.dislikePost(post.postId);
      const { increment } = resp.data;
      if (increment <= -1) {
        setDisliked(true);
        setLiked(false);
        setLikes((likes) => likes + increment);
      }
    } catch (error) {}
  };

  const createComment = async () => {
    if (!isLogin) return;
    try {
      const body = editorRef.current.getHTML();
      await ApiService.replyPost(post.postId, null, body);
      window.location.reload();
    } catch (error) {}
  };

  return (
    <Layout>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ padding: "1rem" }}>
        {" "}
        <Box sx={{ padding: "1rem", backgroundColor: "#fafafa" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <div className={classes.iconDiv}>
              <ArrowBackIosIcon
                className={classes.icon}
                onClick={() => navigate(-1)}
              ></ArrowBackIosIcon>
            </div>
            <div className={classes.category}> 吹水台 </div>
            <div className={classes.title}>{post?.title}</div>
          </Box>
          <div className={classes.authorRow}>
            <UserLink user={post?.user} />
            <ReplyTime>{createdAt}</ReplyTime>
          </div>
          <div className={classes.body}>
            {parse(post?.body || "", {
              trim: true,
            })}
          </div>
          <VSpacer />
          <div className={classes.thumbsRow}>
            <div>
              {liked ? (
                <ThumbUpIcon fontSize="small" color="primary" />
              ) : (
                <ThumbUpAltIcon fontSize="small" onClick={() => like()} />
              )}
            </div>
            <div> &nbsp; {likes} &nbsp; </div>
            <div>
              {disliked ? (
                <ThumbDownIcon fontSize="small" color="primary" />
              ) : (
                <ThumbDownAltIcon fontSize="small" onClick={() => dislike()} />
              )}
            </div>
            <div></div>
          </div>
          {isLogin ? (
            <>
              <div className={classes.editorContainer}>
                <div>
                  <WolfEditor ref={editorRef} />
                </div>
                <VSpacer />
                <div className={classes.btns}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => createComment()}
                    className={classes.commentBtn}
                    sx={{
                      borderRadius: 2,
                    }}
                  >
                    {t("comment")}
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div className={classes.loginMessage}>{t("login_first")}</div>
          )}
        </Box>
        <div className={classes.replies}>
          <h3> {t("reply_area")}</h3>
          {replies.map((reply, index) => {
            return (
              <>
                <div key={index}>
                  <PostReply reply={reply} />
                </div>
                {index < replies.length - 1 && <Divider />}
              </>
            );
          })}
        </div>
      </Box>
    </Layout>
  );
}
