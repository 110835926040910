import { useRef, useState, useEffect } from "react";
import { Checkbox, TextField, Button, Snackbar, Alert } from "@mui/material";

import ApiService from "services/api.service";
import { formatTimeDistance } from "services/utils.service";
import ReplyIcon from "@mui/icons-material/Reply";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { makeStyles } from "@mui/styles";
import { VSpacer, AuthorLabel } from "components/styled.components";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import ReplyEditor from "./reply_editor.component";
import useUser from "../store/user/useUser";
import useGeneral from "../store/general/useGeneral";
import usePost from "../store/post/usePost";

import PostSubReply from "./post_subreply.component";
import { UserName, ReplyTime } from "./styled.components";
import UserLink from "./user_link.component";
import * as _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fafafa",
    display: "flex",
    flexDirection: "column",
  },
  replyNum: {
    color: "#FF3333",
  },
  replyIcon: {
    color: "grey",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    "& > :nth-child(2)": {
      marginLeft: "5px",
    },
  },
  content: {
    "& > p": {
      margin: "0px",
    },
  },
  iconRow: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    "& > :not(:first-child)": {
      marginLeft: "10px",
    },
    cursor: "pointer",
  },
  icon: {
    width: "1rem",
    height: "1rem",
  },
  editorContainer: {
    padding: "2vh",
  },
  replies: {
    marginLeft: "1rem",
  },
  hspace: {
    width: "10px",
    display: "inline-block",
  },
  btns: {
    display: "flex",
    justifyContent: "flex-end",
  },
  subreply: {
    marginTop: "10px",
  },
}));

export default ({ reply }) => {
  const classes = useStyles();
  const [showReplyEditor, setShowReplyEditor] = useState(false);
  const [childReplies, setChildReplies] = useState(reply.replies || []);
  const [likes, setLikes] = useState(reply.likes || 0);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const { isLogin } = useUser();
  const { t } = useTranslation("common");
  const replyTime = new Date(reply.replyAt);
  const { openLoginModal, checkLoginFirst } = useGeneral();
  const editorRef = useRef();
  const [errorOpen, setErrorOpen] = useState(false);
  const { replyLikes, author } = usePost();

  useEffect(() => {
    const status = _.find(replyLikes, (status) => status.replyId == reply.replyId);
    setLiked(status?.liked);
    setDisliked(status?.disliked);
  }, [replyLikes]);

  const getIconColor = () => {
    return isLogin ? "" : "disabled";
  };

  const likeReply = async () => {
    if (!isLogin) return;
    try {
      const resp = await ApiService.likeReply(reply.replyId);
      const inc = resp.data.increment;
      setLikes((likes) => likes + inc);
      setLiked(true);
      setDisliked(false);
    } catch (error) {
      setErrorOpen(true);
    }
  };

  const dislikeReply = async () => {
    if (!isLogin) return;
    try {
      const resp = await ApiService.dislikeReply(reply.replyId);
      const inc = resp.data.increment;
      setLikes((likes) => likes + inc);
      setDisliked(true);
      setLiked(false);
    } catch (error) {
      setErrorOpen(true);
    }
  };

  const createReply = async () => {
    try {
      const body = editorRef.current.getHTML();
      const resp = await ApiService.replyPost(reply.postId, reply.replyId, body);
      const newReply = resp.data;
      setChildReplies([...childReplies, newReply]);
      setShowReplyEditor(false);
    } catch (error) {
      setErrorOpen(true);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleRow}>
        <UserLink user={reply?.user} />
        {author?.id == reply.user.id && <AuthorLabel>{t("author")}</AuthorLabel>}
        <ReplyTime>{formatTimeDistance(replyTime)}</ReplyTime>
        <div className={classes.iconRow}>
          <div>
            <ReplyIcon
              className={classes.replyIcon}
              fontSize="small"
              onClick={() => {
                if (isLogin) {
                  setShowReplyEditor(() => !showReplyEditor);
                } else {
                  openLoginModal();
                }
              }}
              color={getIconColor()}
            />
          </div>
          <div>
            {liked ? (
              <ThumbUpIcon fontSize="small" color="primary" className={classes.icon} />
            ) : (
              <ThumbUpAltIcon
                fontSize="small"
                onClick={() => likeReply()}
                className={classes.icon}
              />
            )}
          </div>
          <div>{likes}</div>
          <div>
            {disliked ? (
              <ThumbDownIcon fontSize="small" color="primary" className={classes.icon} />
            ) : (
              <ThumbDownAltIcon
                fontSize="small"
                onClick={() => dislikeReply()}
                className={classes.icon}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classes.content}>{parse(reply.body)}</div>
      {showReplyEditor && isLogin && (
        <div className={classes.editorContainer}>
          <ReplyEditor ref={editorRef} />
          <VSpacer />
          <div className={classes.btns}>
            <Button variant="contained" color="primary" onClick={() => createReply()}>
              {t("reply")}
            </Button>
            <span className={classes.hspace}></span>
            <Button variant="contained" color="primary" onClick={() => setShowReplyEditor(false)}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      )}

      <div className={classes.replies}>
        {_.map(childReplies, (subReply) => (
          <div className={classes.subreply}>
            <PostSubReply subReply={subReply}></PostSubReply>
          </div>
        ))}
      </div>

      <Snackbar open={errorOpen} autoHideDuration={5000} onClose={() => setErrorOpen(false)}>
        <Alert elevation={6} variant="filled" severity="error">
          {t("system_error")}
        </Alert>
      </Snackbar>
    </div>
  );
};
