import { useSelector, useDispatch } from "react-redux";
import { SET_USER, LOGOUT } from "./user.actions";
import { createSelector } from "reselect";
import ApiService from "../../services/api.service";

const userSelector = (state) => state.user;
const isLoginSelector = createSelector(userSelector, (user) => user.isLogin);

const useUser = () => {
  const dispatch = useDispatch();

  const isLogin = useSelector(isLoginSelector);

  const userProfile = useSelector(userSelector);

  const setUser = (profile) =>
    dispatch({
      type: SET_USER,
      profile,
    });

  const logout = () => {
    dispatch({
      type: LOGOUT,
    });
    window.localStorage.removeItem("jwt");
  };

  const loadUser = () => {
    ApiService.getProfile().then(
      (resp) => {
        setUser({ ...resp.data });
      },
      (err) => {
        //        window.localStorage.removeItem("jwt");
      }
    );
  };

  return { setUser, logout, isLogin, userProfile, loadUser };
};

export default useUser;
