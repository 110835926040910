import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#ec407a",
    },
    error: {
      main: red.A400,
    },
    background: {
      //  default: "#BCC6CC",
      default: "#e9eef1",
    },
  },
});

export default theme;
