import { useRef, useState, useEffect } from "react";
import { Checkbox, TextField, Button, Snackbar, Alert } from "@mui/material";

import ApiService from "services/api.service";
import { formatTimeDistance } from "services/utils.service";
import ReplyIcon from "@mui/icons-material/Reply";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { makeStyles } from "@mui/styles";
import { VSpacer, AuthorLabel } from "components/styled.components";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import ReplyEditor from "./reply_editor.component";
import useUser from "../store/user/useUser";
import useGeneral from "../store/general/useGeneral";
import usePost from "../store/post/usePost";

import PostSubReply from "./post_subreply.component";
import { UserName, ReplyTime } from "./styled.components";
import * as _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fafafa",
    display: "flex",
    flexDirection: "column",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    "& > :nth-child(2)": {
      marginLeft: "0.5rem",
    },
  },
  content: {
    "& > p": {
      marginTop: "0px",
    },
  },
  iconRow: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    "& > :not(:first-child)": {
      marginLeft: "10px",
    },
    cursor: "pointer",
  },
  icon: {
    width: "1rem",
    height: "1rem",
  },
}));

export default ({ subReply }) => {
  const classes = useStyles();
  const { isLogin } = useUser();
  const [likes, setLikes] = useState(subReply.likes || 0);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const { t } = useTranslation("common");
  const likeReply = async () => {
    if (!isLogin) return;
    try {
      const resp = await ApiService.likeReply(subReply.replyId);
      const inc = resp.data.increment;
      setLikes((likes) => likes + inc);
      setLiked(true);
      setDisliked(false);
    } catch (error) {}
  };
  const dislikeReply = async () => {
    if (!isLogin) return;
    try {
      const resp = await ApiService.dislikeReply(subReply.replyId);
      const inc = resp.data.increment;
      setLikes((likes) => likes + inc);
      setLiked(false);
      setDisliked(true);
    } catch (error) {}
  };

  const { replyLikes, author } = usePost();

  useEffect(() => {
    const status = _.find(replyLikes, (status) => status.replyId == subReply.replyId);
    setLiked(status?.liked);
    setDisliked(status?.disliked);
  }, [replyLikes]);
  return (
    <div className={classes.root}>
      <div className={classes.titleRow}>
        <UserName>{subReply?.user?.username}</UserName>
        {author?.id == subReply.user.id && <AuthorLabel>{t("author")}</AuthorLabel>}
        <ReplyTime>{formatTimeDistance(new Date(subReply.replyAt))}</ReplyTime>
        <div className={classes.iconRow}>
          <div>
            {liked ? (
              <ThumbUpIcon fontSize="small" color="primary" className={classes.icon} />
            ) : (
              <ThumbUpAltIcon
                fontSize="small"
                onClick={() => likeReply()}
                className={classes.icon}
              />
            )}
          </div>
          <div>{likes}</div>
          <div>
            {disliked ? (
              <ThumbDownIcon fontSize="small" color="primary" className={classes.icon} />
            ) : (
              <ThumbDownAltIcon
                fontSize="small"
                onClick={() => dislikeReply()}
                className={classes.icon}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classes.content}>{parse(subReply.body || "")}</div>
    </div>
  );
};
