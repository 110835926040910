import "./App.css";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, Switch } from "react-router-dom";
import PostList from "./routes/post_list";
import PostDetails from "./routes/post_details";
import Profile from "./routes/profile";
import Admin from "./routes/admin";
import UseTerm from "./routes/use_term";
import useUser from "./store/user/useUser";
import useGeneral from "./store/general/useGeneral";
import Signup from "./routes/signup";
import NewPost from "./routes/new_post";
import UserActivity from "./routes/user_activity";
import UserVerify from "./routes/user_verify";
import Todo from "./routes/todo";
import RequireAdmin from "./components/require_admin.component";

function App() {
  const { loadUser } = useUser();
  const { loadCategories } = useGeneral();

  useEffect(() => {
    if (window.localStorage.getItem("jwt")) {
      loadUser();
    }
    loadCategories();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/post_list/all" />} />
        <Route path="/todo" element={<Todo />} />
        <Route path="post_details/:id" element={<PostDetails />}></Route>
        <Route path="post_list/:catCode" element={<PostList />}></Route>
        <Route path="profile" element={<Profile />} />
        <Route path="signup" element={<Signup />} />
        <Route path="new_post" element={<NewPost />} />
        <Route path="user_activity/:userId" element={<UserActivity />} />
        <Route path="user_verify" element={<UserVerify />} />
        <Route path="use_term" element={<UseTerm />} />
        <Route
          path="admin"
          element={
            <RequireAdmin>
              <Admin />
            </RequireAdmin>
          }
        />
        <Route
          path="*"
          element={
            <main>
              <p> There is nothing here! </p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
