import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Layout from "components/layout.component";
import { useTranslation } from "react-i18next";
import useUser from "store/user/useUser";
import { useFormik } from "formik";
import * as yup from "yup";
import ApiService from "services/api.service";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { Radio, RadioGroup, FormControlLabel, TextField, Button, Alert } from "@mui/material";
import { Box } from "@mui/system";

const useStyles = makeStyles((themes) => ({
  root: {
    marginLeft: "1rem",
    padding: "1rem",
    backgroundColor: "#fafafa",
    "& > div": {
      marginTop: "1rem",
    },
  },
  row: {
    display: "flex",
    direction: "column",
    "& > :first-child": {
      width: "20%",
    },
    "& > :nth-child(2)": {
      width: "50%",
    },
  },
  btnRow: {
    marginLeft: "20%",
  },
}));

export default function () {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const { userProfile, loadUser, isLogin } = useUser();
  const navigate = useNavigate();
  const [updatedSuccess, setUpdatedSuccess] = useState(false);

  useEffect(() => {
    if (!isLogin) {
      navigate("/");
    }
  }, [isLogin]);

  const validationSchema = yup.object({
    aboutMyself: yup.string().required("required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      aboutMyself: userProfile.aboutMyself,
      sex: userProfile.sex,
    },
    validationSchema,
    onSubmit: (values) => {
      ApiService.updateProfile(values.sex, values.aboutMyself).then((resp) => {
        loadUser();
        setUpdatedSuccess(true);
      });
    },
  });
  return (
    <Layout>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.root}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ padding: "5px", cursor: "pointer" }}>
              <ArrowBackIosIcon fontSize="small" onClick={() => navigate(-1)}></ArrowBackIosIcon>
            </Box>
            <Box sx={{ marginLeft: "2rem", fontSize: "1.2rem", fontWeight: "bold" }}>
              {t("profile")}
            </Box>
          </Box>
          <div className={classes.row}>
            <div> {t("email")} </div>
            <div> {userProfile.email} </div>
          </div>
          <div className={classes.row}>
            <div> {t("username")} </div>
            <div> {userProfile.username}</div>
          </div>
          <div className={classes.row}>
            <div> {t("sex")} </div>
            <div>
              <RadioGroup
                name="sex"
                value={formik.values.sex}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <FormControlLabel value="F" control={<Radio />} label={t("female")} />
                <FormControlLabel value="M" control={<Radio />} label={t("male")} />
              </RadioGroup>
            </div>
          </div>
          <div className={classes.row}>
            <div> {t("about_yourself")}</div>
            <div>
              <TextField
                id="aboutMyself"
                multiline
                fullWidth
                rows={4}
                value={formik.values.aboutMyself}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          {updatedSuccess && (
            <div className={classes.btnRow}>
              <Alert severity="success" sx={{ width: "100%" }}>
                {t("updated_success")}
              </Alert>
            </div>
          )}
          <div className={classes.btnRow}>
            <Button variant="contained" color="primary" type="submit" sx={{ borderRadius: 2 }}>
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </Layout>
  );
}
