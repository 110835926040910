import { useParams, useSearchParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "red",
    },
  },
}));

export default () => {
  const classes = useStyles();

  return <div className={classes.root}>{process.env.NODE_ENV}</div>;
};
