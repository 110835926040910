import { SET_REPLY_STATUSES, SET_AUTHOR } from "./post.actions";

const initState = {
  replyLikeStatuses: [],
  author: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_REPLY_STATUSES:
      return {
        ...state,
        replyLikeStatuses: action.replyLikeStatuses,
      };
    case SET_AUTHOR:
      return {
        ...state,
        author: action.author,
      };
    default:
      return state;
  }
};

export default reducer;
