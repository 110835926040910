import { useRef, forwardRef, useImperativeHandle, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDropzone } from "react-dropzone";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import ApiService from "services/api.service";
import "quill-emoji";

const useStyles = makeStyles((theme) => ({
  editor: {
    backgroundColor: "white",
  },
}));

const WolfEditor = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getHTML: () => {
      return quillRef.current.unprivilegedEditor.getHTML();
    },
    clear: () => {
      quillRef.current.unprivilegedEditor.setText("");
    },
  }));
  const classes = useStyles();
  const quillRef = useRef(null);

  const onDrop = async (acceptedFiles) => {
    for (let file of acceptedFiles) {
      const editor = quillRef.current.getEditor();
      const selection = quillRef.current.selection;
      const currPos = selection ? selection.index || 0 : 0;
      const { data } = await ApiService.upload(file);
      editor.insertEmbed(currPos, "image", data.url);
      editor.setSelection(currPos + 1);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/jpg, image/png",
  });

  const modules = {
    toolbar: {
      container: [
        [
          "bold",
          "italic",
          "underline",
          { size: ["small", false, "large"] },
          { color: [] },
          "emoji",
          "image",
        ],
      ],
      handlers: {
        image: () => {
          open();
        },
      },
    },
    clipboard: { matchVisual: false },
    "emoji-toolbar": true,
  };

  return (
    <>
      <div className={classes.editor}>
        <ReactQuill ref={quillRef} theme="snow" modules={modules} />
        <div {...getRootProps()}>
          <input {...getInputProps()} />
        </div>
      </div>
    </>
  );
});

export default WolfEditor;
