import { useEffect, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  Modal,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { ErrCodes } from "constants";
import ApiService from "services/api.service";
import { ErrText } from "components/styled.components";
import { VSpacer, AnswerField } from "../components/styled.components";
import Canvas from "../components/canvas.component";
import useLoader from "../hooks/useLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fafafa",
    margin: "auto",
    marginTop: "2rem",
    [theme.breakpoints.up("md")]: {
      width: "600px",
    },
    padding: "1rem",
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "large",
  },
  form: {
    margin: "auto",
    marginTop: "2rem",
    width: "80%",
  },
  modal: {
    position: "absolute",
    left: "20%",
    width: "60%",
  },
}));

const getRandNum = () => {
  return Math.floor(Math.random() * 30 + 1);
};

export default () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [errCode, setErrCode] = useState(null);
  const [num1, setNum1] = useState(getRandNum());
  const [num2, setNum2] = useState(getRandNum());
  const [isVerified, setVerified] = useState(true);
  const [success, setSuccess] = useState(false);
  const { isLoading, data: signupData, error: signupError, execute } = useLoader();

  useEffect(() => {
    if (signupData) {
      window.localStorage.setItem("jwt", signupData.token);
      navigate("/");
    }
  }, [signupData]);

  useEffect(() => {
    if (signupError) {
      setErrCode(signupError.errCode);
    }
  }, [signupError]);

  const validationSchema = yup.object({
    email: yup.string("Email").email(t("email_invalid")).required(t("email_required")),
    username: yup.string("User Name").required(t("username_required")),
    password: yup
      .string()
      .required(t("password_required"))
      .test("len", t("password_min_length"), (val) => {
        return val?.length >= 6;
      }),
    confirmPassword: yup
      .string()
      .required(t("confirm_password_required"))
      .when("password", {
        is: (password) => (password && password.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref("password")], t("confirm_password_matched")),
      }),
    acceptTerms: yup.bool().oneOf([true], t("accept_required")),
  });

  const getErrMessage = (errCode) => {
    switch (errCode) {
      case ErrCodes.EMAIL_ALREADY_EXISTS:
        return t("email_already_used");
      case ErrCodes.USERNAME_ALREADY_EXISTS:
        return t("username_already_used");
      default:
        return null;
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      acceptTerms: false,
      answer: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { username, email, password } = values;
      const answer = parseInt(values.answer);
      if (answer !== num1 + num2) {
        setVerified(false);
        setNum1(getRandNum());
        setNum2(getRandNum());
        return;
      }

      execute(() => {
        return ApiService.signup(username, email, password);
      });
    },
  });

  const isError = (fieldName) => {
    return formik.touched[fieldName] && Boolean(formik.errors[fieldName]);
  };

  const getErrorText = (fieldName) => {
    if (isError(fieldName)) {
      return formik.errors[fieldName];
    }

    return "";
  };

  const draw = (ctx, count) => {
    const question = `${num1} + ${num2} =`;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = "#000000";
    ctx.font = "18px Arial";
    ctx.beginPath();
    ctx.fillText(question, 5, 15);
    ctx.fill();
  };

  return (
    <div className={classes.root}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.title}>{t("title")}</div>
      <div className={classes.form}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="email"
                placeholder={t("email")}
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={isError("email")}
                helperText={getErrorText("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="username"
                placeholder={t("username")}
                fullWidth
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={isError("username")}
                helperText={getErrorText("username") || t("username_helptext")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password"
                type="password"
                placeholder={t("password")}
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={isError("password")}
                helperText={getErrorText("password")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="confirmPassword"
                type="password"
                placeholder={t("confirm_password")}
                fullWidth
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={isError("confirmPassword")}
                helperText={getErrorText("confirmPassword")}
              />
            </Grid>

            <Grid item xs={12}>
              <Checkbox
                id="acceptTerms"
                value={formik.values.acceptTerms}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span>{t("read_accept")}</span>
              <span>
                <Link to="/use_term" target="_blank">
                  {t("user_agreement")}{" "}
                </Link>
              </span>
            </Grid>

            <Grid item xs={12}>
              {formik.submitCount > 0 && <ErrText>{formik.errors.acceptTerms}</ErrText>}
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ padding: "0.5em" }}>{t("verification_label")}</Box>
              <Box sx={{ marginTop: "0.5em", display: "flex", direction: "row" }}>
                <Box sx={{ padding: "0.5em" }}>
                  <Canvas draw={draw} width="100" height="40" />
                </Box>
                <Box sx={{ width: "60px", marginLeft: "-0.5em" }}>
                  <AnswerField
                    fullWidth
                    id="answer"
                    value={formik.values.answer}
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box sx={{ padding: "0.5em", marginLeft: "0.5em" }}>
                  {!isVerified && <ErrText> {t("verification_error")}</ErrText>}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <ErrText> {getErrMessage(errCode)}</ErrText>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary" size="large" type="submit">
                {t("sign_up")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                sx={{ marginLeft: "1rem" }}
                onClick={() => navigate("/")}
              >
                {t("return")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};
