import axios from "axios";

const instance = axios.create({});

instance.interceptors.request.use((config) => {
  const token = typeof window !== "undefined" && window.localStorage.getItem("jwt");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

const ApiService = {
  greet: function () {
    return instance.get("/api/public/greet");
  },
  getUserProfile: function (id) {
    return instance.get(`/api/public/user_profiles/${id}`);
  },
  getPagePosts: function (catId, sortBy, page) {
    return instance.get(`/api/public/categories/${catId}/posts`, {
      params: {
        page,
        size: 10,
      },
    });
  },
  getPostsCreatedBy: function (userId) {
    return instance.get(`/api/public/posts`, {
      params: {
        createdBy: userId,
      },
    });
  },
  getCategories: function () {
    return instance.get("/api/public/categories");
  },
  getCategoryInfo: function (catId) {
    return instance.get(`/api/public/categories/${catId}/info`);
  },
  getPostDetails: function (id) {
    return instance.get(`/api/public/posts/${id}/details`);
  },
  getPostReplies: function (id) {
    return instance.get(`/api/public/posts/${id}/replies`);
  },
  createPost: function (catId, title, body) {
    return instance.post("/api/secured/posts/create", {
      catId,
      title,
      body,
    });
  },
  likePost: function (id) {
    return instance.post(`/api/secured/posts/${id}/like`);
  },
  alreadyLikedPost: function (id) {
    return instance.get(`/api/secured/posts/${id}/liked`);
  },
  alreadyDislikedPost: function (id) {
    return instance.get(`/api/secured/posts/${id}/disliked`);
  },
  dislikePost: function (id) {
    return instance.post(`/api/secured/posts/${id}/dislike`);
  },
  getPostReplyLikes: function (id) {
    return instance.get(`/api/secured/posts/${id}/reply_likes`);
  },
  getProfile: function (token) {
    return instance.get(`/api/secured/user_profile`);
  },
  updateProfile: function (sex, aboutMyself) {
    return instance.post("/api/secured/user_profile", {
      sex,
      aboutMyself,
    });
  },
  replyPost: function (postId, parentId, body) {
    return instance.post(`/api/secured/posts/${postId}/reply`, {
      body,
      parentId,
    });
  },
  likeReply: function (replyId) {
    return instance.post(`/api/secured/post_replies/${replyId}/like`);
  },
  dislikeReply: function (replyId) {
    return instance.post(`/api/secured/post_replies/${replyId}/dislike`);
  },
  login: function (username, password) {
    return instance.post("/api/auth/login", {
      username,
      password,
    });
  },
  signup: function (username, email, password) {
    return instance.post("/api/auth/signup", {
      username,
      email,
      password,
    });
  },
  demo: function (page) {
    return instance.get("/demo", { params: { page } });
  },
  upload: function (file) {
    const formData = new FormData();
    formData.append("file", file);
    return instance.post("/api/secured/upload", formData);
  },
  verify: function (token) {
    return instance.get("/api/public/verify", { params: { token } });
  },
  getInternalUsers: function () {
    return instance.get("/api/admin/internal_users");
  },
  createPostAsAdmin: function (userId, catId, title, body) {
    return instance.post("/api/admin/create_post", {
      userId,
      catId,
      title,
      body,
    });
  },
};

export default ApiService;
