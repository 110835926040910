import { Grid, Link, Box } from "@mui/material";
import { formatTimeDistance } from "services/utils.service";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import { ReplyTime } from "./styled.components";
import { useTranslation } from "react-i18next";

const icon = {
  width: "15px",
  height: "15px",
};

const iconLabel = {
  marginLeft: "1vh",
  fontSize: "small",
  color: "#A9A9A9",
};

const iconSection = {
  display: "flex",
  alignItems: "center",
};

export default ({ summary }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  if (!summary) return <div></div>;

  return (
    <Grid container sx={{ background: "#fafafa", borderRadius: "10px", padding: "1rem" }}>
      <Grid item>
        <Grid container spacing={1}>
          <Grid item>
            <Box sx={{ color: "secondary.main", fontSize: "1rem" }}>{summary?.user?.username}</Box>
          </Grid>
          <Grid item>
            <ReplyTime>{formatTimeDistance(summary.updatedAt)}</ReplyTime>
          </Grid>
          <Grid item>
            <span style={{ width: "10px" }} />
          </Grid>
          <Grid item sx={iconSection}>
            <ThumbUpIcon fontSize="small" sx={icon} color="disabled" />
            <Box sx={iconLabel}> {summary.likes} </Box>
          </Grid>
          <Grid item sx={iconSection}>
            <CommentIcon fontSize="small" sx={icon} color="disabled" />
            <Box sx={iconLabel}> {summary.comments}</Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ cursor: "pointer", marginTop: "10px", fontSize: "18px" }}>
        <Link
          underline="none"
          color="primary"
          onClick={() => navigate(`/post_details/${summary.postId}`)}
        >
          {summary.title}
        </Link>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <TruncateMarkup
            lines={8}
            ellipsis={() => (
              <Link
                underline="none"
                onClick={() => navigate(`/post_details/${summary.postId}`)}
                sx={{ cursor: "pointer" }}
              >
                {t("more_details")}
              </Link>
            )}
          >
            {parse(summary.body || "", {
              replace: (domNode) => {
                return domNode?.name === "img" && <span>(图片)</span>;
              },
              trim: true,
            })}
          </TruncateMarkup>
        </Box>
      </Grid>
    </Grid>
  );
};
